<template>
	<div>
		<div class="queryBox mb20">
			<el-button class="mr20" size="mini" type="primary" icon="el-icon-plu" @click="addAccount">新 增</el-button>
			
				<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
				<el-date-picker size="mini" v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
				<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"
					@click="getData" :disabled="disLoading">查询</el-button>
			<el-button class="mr20" size="mini" type="primary" icon="el-icon-document" @click="getExcelCarder" :disabled="tableData.length == 0">导出搬砖工列表</el-button>
			<el-link type="primary" @click="showAllLog()">
				额度变动日志
			</el-link>
		</div>
		<div>
			<el-table :max-height="height" v-loading="loading" stripe :data="tableData" :indent="40" row-key="id" size="mini" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
				<el-table-column label="用户名/账号" width="300px" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-if="scope.row.name != '总计'" class="treeData" type="primary">{{scope.row.name}}-{{scope.row.username}}</span>
						<span v-if="scope.row.name == '总计'">{{scope.row.name}}</span>
					</template>
				</el-table-column>
				<el-table-column label="押金" >
					<template slot-scope="scope">
						<span class="">{{scope.row.advance_money | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="可用额度" >
					<template slot-scope="scope">
						<span>{{scope.row.quota}}</span>
					
					</template>
				</el-table-column>
				<el-table-column label="当日收款" >
					<template slot-scope="scope">
						<span :class="{cdanger:Number(scope.row.money_in)>=Number(scope.row.money_quota)}">{{scope.row.money_in}}</span>
					</template>
				</el-table-column>
				<el-table-column label="当日出款" >
					<template slot-scope="scope">
						<span class="">{{scope.row.money_out | tofixed}}</span>
					</template>
				</el-table-column>
				
				<el-table-column label="账号" >
					<template slot-scope="scope">
						<el-switch v-model="scope.row.switchState" active-color="#13ce66" inactive-color="#ff4949" @change="setState(scope.row)"></el-switch>
						<!-- <el-link class="mr20" :type="scope.row.state == 0?'danger':'primary'" @click="setState(scope.row)">{{scope.row.state == 0?'关闭':'开启'}}</el-link> -->
					</template>
				</el-table-column>
				<el-table-column label="开工状态"  >
					<template slot-scope="scope">
						<el-switch v-model="scope.row.switchManual_state" active-color="#13ce66" inactive-color="#ff4949" @change="setManual_state(scope.row)"></el-switch>
						
						<!-- <el-link class="mr20" :type="scope.row.manual_state== 0?'primary':'danger'" @click="setManual_state(scope.row)">{{scope.row.manual_state== 0?'开工':'停工'}}</el-link> -->
					</template>
				</el-table-column>
				<el-table-column label="充值状态" >
					<template slot-scope="scope">
						<el-link class="mr20" :type="scope.row.pay_state== 0?'danger':'primary'" @click="setPay_state(scope.row)">{{scope.row.pay_state== 0?'充值禁用':'充值启用'}}</el-link>
					</template>
				</el-table-column>

				<el-table-column label="代付状态" >
					<template slot-scope="scope">
						<el-link class="mr20" :type="scope.row.repay_state== 0?'danger':'primary'" @click="setRepay_state(scope.row)">{{scope.row.repay_state== 0?'代付禁用':'代付启用'}}</el-link>
					</template>
				</el-table-column>

				
				<el-table-column label="操作"width="140px">
					<template slot-scope="scope">
						<div v-if="scope.row.name != '总计'">
							<el-link class="mr20" type="primary" @click="showInfo(scope.row)">
								编辑
							</el-link>
							<el-link class="mr20" type="primary" @click="deleteCarder(scope.row)">
								删除
							</el-link>
							<el-link class="mr20" type="primary" @click="showLog(scope.row)">
								日志
							</el-link>							
								
						</div>
					</template>
				</el-table-column>

			</el-table>
		</div>
		<!-- 新增搬砖工 -->
		<el-dialog class="dialog" top="200px" center title="新增搬砖工" :visible.sync="adddialogVisible" @close="addclose"
		 :close-on-click-modal="false">
			<div class="formBox" v-loading="adddialogloading">
				<div>
					<el-form :label-position="labelPosition" label-width="100px">
						<el-form-item label="名称">
							<el-input v-model="name" autocomplete="new-password" placeholder="请输入名称"></el-input>
						</el-form-item>
						<el-form-item label="登录账号">
							<el-input v-model="account" placeholder="请输入登录账号"></el-input>
						</el-form-item>
						<el-form-item label="登录密码">
							<el-input type="password" autocomplete="new-password" v-model="password" placeholder="请输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="确认登录密码">
							<el-input type="password" autocomplete="new-password" v-model="passwordSure" placeholder="请再次输入登录密码"></el-input>
						</el-form-item>
						<el-form-item label="手机号">
							<el-input type="text" v-model="phone" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="Skype">
							<el-input v-model="skype" placeholder="请输入Skype"></el-input>
						</el-form-item>
					<!-- 	<el-form-item label="手续费">
							<div class="flexX">
								<el-input class="mr20" v-model="fee" placeholder="请输入手续费"></el-input>
								<span class="inlineBlock feeAround">最大值：{{feeAround}}</span>
							</div>
						</el-form-item> -->
					</el-form>

				</div>
				<div class="textCenter">
					<el-button type="primary" @click="onSubmit">新增</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog class="dialog" top="60px" center title="" :visible.sync="dialogVisible" @close="close" :close-on-click-modal="false" width="70%">
			<el-tabs type="border-card" @tab-click="getInfoData" v-model="activeName" v-loading="dialogloading">
				<el-tab-pane label="搬砖工" name='info'>
					<div class="formBox">
						<div class="item flexX flexAround">
							<div>
								<el-form label-position="right" label-width="80px">
									<el-form-item label="用户名:">{{item.name}}</el-form-item>
									<el-form-item label="账号:">{{item.username}}</el-form-item>
									<el-form-item label="Skype:">
										<el-link type="primary" :href="`skype:${item.skype}?chat`" οnclick="return skypeCheck();">{{item.skype}}</el-link>
									</el-form-item>
									<el-form-item label="手机号:">{{item.telephone}}</el-form-item>
									<el-form-item label="上级:">{{item.all_parent_name}}</el-form-item>
								</el-form>
							</div>
							<div class="lineY"></div>
							<div>
								<el-form label-position="right" label-width="130px">
									<el-form-item label="提现金额:">{{item.tx_money | tofixed}}</el-form-item>
									<el-form-item label="今日奖金:">{{item.today_yj | tofixed}}</el-form-item>
									<el-form-item label="今日提成:">{{item.today_tc | tofixed}}</el-form-item>
									<el-form-item label="支付宝个数:">{{item.alipay_count}}</el-form-item>
									<el-form-item label="聚合个数:">{{item.ag_code_count}}</el-form-item>
									<el-form-item label="数字个数:">{{item.number_rmb_count}}</el-form-item>
									<el-form-item label="充值状态:"> <span :class="item.pay_state==0?'csuccess':'cdanger'">{{item.pay_state==0?'启用':'禁用'}}</span></el-form-item>
									<el-form-item label="代付状态:"> <span :class="item.repay_state==0?'csuccess':'cdanger'">{{item.repay_state==0?'启用':'禁用'}}</span></el-form-item>
									<el-form-item label="手动充值&代付:"> <span :class="item.manual_state==0?'cdanger':'csuccess'">{{item.manual_state==0?'停用':'启用'}}</span></el-form-item>
								</el-form>
							</div>
							<div class="lineY"></div>
							<div>
								<div class=" textCenter"> <b>操作</b></div>
							
								<div class="mt20">
								
									<span class="mr20">
										<el-link type="primary" @click="repassword()">重置密码</el-link>
									</span>
								</div>
							
								<div class="mt20">
									<span class="mr20">
										<el-link type="primary" @click="resGoogleCode()">重置谷歌验证码</el-link>
									</span>
									
								</div>								
							</div>
						</div>
					</div>
					<div class="textCenter mt20">
						<el-button type="primary" @click="close">关 闭</el-button>
					</div>
					
				</el-tab-pane>
				<el-tab-pane label="调额" name='changeMoney'>
					<el-form label-width="120px" class="mb20" >
					<el-form-item class="mt20" label="调额:" >
						<el-input v-model="amount" style="width: 400px">
							<template slot="prepend">￥</template>
						</el-input>							
						<div>如需扣除预付，则输入负数。例如：-300</div>
					</el-form-item>
					
					<el-form-item class="mt20" label="谷歌验证码:">
						<el-input v-model="googleCode" placeholder="请输入谷歌验证码" style="width: 400px"></el-input>
					</el-form-item>

					<el-form-item class="mt20" label="备注:" >
						<el-input type="textarea" :rows="2" v-model="setAmount_note" placeholder="请输入备注" style="width: 400px"></el-input>
					</el-form-item>
					</el-form>
					<div class="textCenter mt20">
						<el-button type="primary" @click="rechargeSuer">确定</el-button>
					</div>
					
				</el-tab-pane>
				<el-tab-pane label="调押金" name='changeAdvanceMoney'>
					<el-form label-width="120px" class="mb20" >
					<el-form-item class="mt20" label="调押金:" >
						<el-input v-model="advance.amount" style="width: 400px">
							<template slot="prepend">￥</template>
						</el-input>							
						<div>如需扣除预付，则输入负数。例如：-300</div>
					</el-form-item>
					
					<el-form-item class="mt20" label="谷歌验证码:">
						<el-input v-model="advance.googleCode" placeholder="请输入谷歌验证码" style="width: 400px"></el-input>
					</el-form-item>

					<el-form-item class="mt20" label="备注:" >
						<el-input type="textarea" :rows="2" v-model="advance.setAmount_note" placeholder="请输入备注" style="width: 400px"></el-input>
					</el-form-item>
					</el-form>
					<div class="textCenter mt20">
						<el-button type="primary" @click="changeAdvanceMoney">确定</el-button>
					</div>
					
				</el-tab-pane>
				
				<el-tab-pane label="设置手续费" name='changeFee'>
					<el-form >
						<div class="mb20">代理账号:{{item.name}}-{{item.username}}</div>
						<el-table :data="feeData" stripe size="mini" border width="50%">
							<el-table-column prop="name" label="通道名称" ></el-table-column>
							<el-table-column label="手续费率(‰)" >
								<template slot-scope="scope">
									<el-input style="width: 80px;" v-model="scope.row.fee" placeholder="请输入手续费" class="mr20"></el-input>
									<span>最小值:{{scope.row.min_fee}} , 最大值:{{scope.row.max_fee}}</span>
								</template>
							</el-table-column>
							
						</el-table>
						<el-pagination :hide-on-single-page="true" @size-change="feehandleSizeChange" @current-change="feehandleCurrentChange"
						:current-page="feepage" :page-sizes="feepageSizesArr" :page-size="feepageSize" layout="total, sizes, prev, pager, next, jumper"
						:total="feetotal"></el-pagination>
					</el-form>
					<div class="textCenter mt20">
						<el-button type="primary" @click="suer">确定</el-button>
					</div>
				</el-tab-pane>
				<el-tab-pane label="卡列表" name='bankList'>
					<div class="mb20">
						<span class="mr20 mb20">用户名：{{item.name}}</span>
						<span class="mr20 mb20">账号：{{item.username}}</span>
						<span class="mr20 mb20">累计收款上限：{{cardListData.length>0?cardListData[cardListData.length-1].eve_used_money:'0.00'}}</span>
						<span class="mr20 mb20">留存上限：{{cardListData.length>0?cardListData[cardListData.length-1].keep_gold:'0.00'}}</span>
						<span class="mr20 mb20">真实余额：{{cardListData.length>0?cardListData[cardListData.length-1].true_quota:'0.00'}}</span>
					</div>
					<div class="mb20">
						<el-button size="mini" plain class="mr20" @click="cardListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="cardListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="cardListgetData">搜索</el-button>
						<el-button size="mini" type="primary" icon="el-icon-document" @click="getExcel" :disabled="cardListData.length == 0">导出Excel</el-button>
					</div>
					<el-table size="mini" :data="cardListData" border="" stripe max-height="500px">
						<el-table-column prop="card" label="卡号" width="145px" >
							<template slot-scope='scope'>
								<div v-if="scope.$index < cardListData.length-2">{{scope.row.card}}</div>
								<div v-else="">{{scope.row.all_parent_name}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="bank" label="银行" ></el-table-column>
						<el-table-column prop="true_name" label="姓名" ></el-table-column>
						<el-table-column prop="telephone" label="电话" ></el-table-column>
						<el-table-column label="昨日结余" >
							<template slot-scope='scope'>
								<div>{{scope.row.yes_sys_quota | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="今日收款" >
							<template slot-scope='scope'>
								<div>{{scope.row.money_in | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="今日出款" >
							<template slot-scope='scope'>
								<div>{{scope.row.money_out | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="当前余额" >
							<template slot-scope='scope'>
								<div>{{scope.row.sys_quota | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="累计收款上限" >
							<template slot-scope='scope'>
								<div>{{scope.row.eve_used_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="留存上限" >
							<template slot-scope='scope'>
								<div>{{scope.row.keep_gold | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="真实余额" >
							<template slot-scope='scope'>
								<div>{{scope.row.true_quota | tofixed}}</div>
							</template>
						</el-table-column>
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="diahandleSizeChange" @current-change="diahandleCurrentChange"
						 :current-page="diapage" :page-sizes="diapageSizesArr" :page-size="diapageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="diatotal"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>

		</el-dialog>
		<el-dialog center width="50%" :title="innerDialogTitle" :visible.sync="innerVisible" append-to-body @close="closeInner"
		 :close-on-click-modal="false">
			<div v-loading="dialogInnerloading">
				<el-form label-width="120px" class="mb20" v-if="type != 'fee'">
					<el-form-item class="mt20" label="代理账号:">
						<span>{{item.name}}-{{item.username}}</span>
					</el-form-item>
					<el-form-item class="mt20" label="调额:" v-if="type == 'recharge'">
						<el-input v-model="amount">
							<template slot="prepend">￥</template>
						</el-input>							
						<div>如需扣除预付，则输入负数。例如：-300</div>
					</el-form-item>
					
					<el-form-item class="mt20" label="谷歌验证码:" v-if="type == 'recharge'">
						<el-input v-model="googleCode" placeholder="请输入谷歌验证码"></el-input>
					</el-form-item>

					<el-form-item class="mt20" label="备注:" v-if="type == 'deduction' || type == 'recharge'">
						<el-input type="textarea" :rows="2" v-model="setAmount_note" placeholder="请输入备注"></el-input>
					</el-form-item>
					<el-form-item class="mt20" label="限制充值金额:" v-if="type == 'limit'">
						<el-input v-model="amount" placeholder="请输入限制充值金额"></el-input>
					</el-form-item>
					
				</el-form>
				<el-form v-if="type == 'fee'">
					<div class="mb20">代理账号:{{item.name}}-{{item.username}}</div>
					<el-table :data="feeData" stripe size="mini" border width="50%">
						<el-table-column prop="name" label="通道名称" ></el-table-column>
						<el-table-column label="手续费率(‰)" >
							<template slot-scope="scope">
								<el-input style="width: 80px;" v-model="scope.row.fee" placeholder="请输入手续费" class="mr20"></el-input>
								<span>最小值:{{scope.row.min_fee}} , 最大值:{{scope.row.max_fee}}</span>
							</template>
						</el-table-column>
						<!-- <el-table-column label="单笔费用" >
							<template slot-scope="scope">
								<el-input style="width: 80px;" v-model="scope.row.fee_num" placeholder="请输入单笔手续费" class="mr20"></el-input>
								<span>最小值:{{scope.row.min_fee_num}} , 最大值:{{scope.row.max_fee_num}}</span>
							</template>
						</el-table-column> -->
					</el-table>
					<el-pagination :hide-on-single-page="true" @size-change="feehandleSizeChange" @current-change="feehandleCurrentChange"
					 :current-page="feepage" :page-sizes="feepageSizesArr" :page-size="feepageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="feetotal"></el-pagination>
				</el-form>
				<el-form label-width="150px" class="mb20" v-if="type == 'txmoney'">
					
					<el-form-item class="mt20" label="当前提现金额:">
						<span>{{item.tx_money}}</span>
					</el-form-item>
					<el-form-item class="mt20" label="账变类型:">
						<el-radio v-model="change_radio" label="1">增加</el-radio>
						  <el-radio v-model="change_radio" label="2">减少</el-radio>
					</el-form-item>
					<el-form-item class="mt20" label="金额:" v-if="type == 'txmoney'">
						<el-input v-model="tx_amount" placeholder="请输入金额"></el-input>
					</el-form-item>
					<el-form-item class="mt20" label="原因:" v-if="type == 'txmoney'">
						<el-input
						  type="textarea"
						  :rows="2"
						  placeholder="请输入账变原因"
						  v-model="tx_note">
						</el-input>
					</el-form-item>
					
				</el-form>
				
				<div class="textCenter mt20">
					<el-button @click="closeInner">取 消</el-button>
					<el-button type="primary" @click="suer">确 定</el-button>
				</div>
			</div>
		
		</el-dialog>
		<!-- 日志 -->
		<el-dialog class="dialog" top="100px" center title="日志" :visible.sync="logdialog" @close="logclose" :close-on-click-modal="false" width="70%">
			<el-tabs type="border-card" @tab-click="getlogData" v-model="logName">
				<el-tab-pane label="充值|扣款" name='recharge' :disabled="logLoding">
					<el-table size="mini" :data="rechargeLog" border="" stripe max-height="500px">
						<el-table-column prop="name" label="操作类型" >
							<template slot-scope='scope'>
								<div :class="{csuccess:scope.row.name=='充值',cdanger:scope.row.name!='充值'}">{{scope.row.name}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作人" ></el-table-column>
						<el-table-column prop="time" label="操作时间" ></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="reLoghandleSizeChange" @current-change="reLoghandleCurrentChange"
						 :current-page="reLogpage" :page-sizes="reLogpageSizesArr" :page-size="reLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="reLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="清空余额" name='deduction' :disabled="logLoding">
					<el-table size="mini" :data="deductionLog" border="" stripe max-height="500px">
						<el-table-column label="信用额度" >
							<template slot-scope='scope'>
								<div>{{scope.row.advance_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="可用额度" >
							<template slot-scope='scope'>
								<div>{{scope.row.used_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="提现额度" >
							<template slot-scope='scope'>
								<div>{{scope.row.tx_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作者" ></el-table-column>
						<el-table-column prop="time" label="操作时间" ></el-table-column>
					</el-table>
					<div class="flexX mt20">
						<el-pagination :hide-on-single-page="true" @size-change="dedLoghandleSizeChange" @current-change="dedLoghandleCurrentChange"
						 :current-page="dedLogpage" :page-sizes="dedLogpageSizesArr" :page-size="dedLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="dedLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="手续费" name='fee' :disabled="logLoding">
					<el-table size="mini" :data="feeLog" border="" stripe max-height="500px">
						<!-- <el-table-column prop="card" label="卡号" width="145px" ></el-table-column> -->
						<el-table-column label="支付类型" >
							<template slot-scope='scope'>
								<div>{{scope.row.pay_type}}</div>
							</template>
						</el-table-column>
						<el-table-column label="费率" >
							<template slot-scope='scope'>
								<div>{{scope.row.fee}}</div>
							</template>
						</el-table-column>
						<el-table-column label="旧费率" >
							<template slot-scope='scope'>
								<div>{{scope.row.old_fee}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作者" ></el-table-column>
						<el-table-column prop="time" label="操作时间" ></el-table-column>
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="feeLoghandleSizeChange" @current-change="feeLoghandleCurrentChange"
						 :current-page="feeLogpage" :page-sizes="feeLogpageSizesArr" :page-size="feeLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="feeLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
			
		</el-dialog>
		<!-- 所有额度变动日志 -->
		<el-dialog class="dialog" top="20px" center title="总体日志" :visible.sync="allLogdialog" @close="allLogclose" :close-on-click-modal="false" width="80%">
			<el-tabs type="border-card" @tab-click="allGetlogData" v-model="logName">
				<el-tab-pane label="信用额度" name='recharge' :disabled="logLoding">
					<div>
						<el-cascader v-model="carder_id" class="mr20 mb20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang" ></el-cascader>
					
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="logListgetData">搜索</el-button>
						
					</div>
						 
					<el-table size="mini" :data="allRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="搬砖工" >
							<template slot-scope='scope'>
								<div>{{scope.row.carder_username}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="操作类型" >
							<template slot-scope='scope'>
								<div :class="{csuccess:scope.row.change_type=='IN',cdanger:scope.row.change_type!='IN'}">{{scope.row.change_type == 'IN'?'充值':scope.row.change_type == 'OUT'?'扣款':''}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="notes" label="备注" ></el-table-column>
						<el-table-column prop="auditor" label="操作人" ></el-table-column>
						<el-table-column prop="add_time" label="操作时间" ></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="allReLogpage" :page-sizes="allReLogpageSizesArr" :page-size="allReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="allReLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				<!-- 可提现金额 -->
				<el-tab-pane label="提现金额" name='txmoney' :disabled="logLoding">
					<div>
						<el-cascader v-model="carder_id" class="mr20 mb20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options" :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang" ></el-cascader>
					
					
						<el-button size="mini" plain class="mr20" @click="logListsetDate(-1)" :disabled="cardListdisLoading">前一天</el-button>
						<el-date-picker size="mini" value-format="yyyy-MM-dd" v-model="cardListdate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" class="mr20"></el-date-picker>
						<el-button size="mini" plain class="mr20" @click="logListsetDate(1)" :disabled="cardListdisLoading">后一天</el-button>
						<el-button size="mini" type="primary" :icon="cardListdisLoading?'el-icon-loading':'el-icon-search'" :disabled="cardListdisLoading" @click="logListgetData">搜索</el-button>
						
					</div>
						 
					<el-table size="mini" :data="allRechargeLog" border=""  max-height="500px"  :row-class-name="tableLogRowClassName">
						<el-table-column label="搬砖工" >
							<template slot-scope='scope'>
								<div>{{scope.row.carder_username}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="操作类型" >
							<template slot-scope='scope'>
								<div :class="{csuccess:scope.row.change_type=='IN',cdanger:scope.row.change_type!='IN'}">{{scope.row.change_type == 'IN'?'增加':scope.row.change_type == 'OUT'?'减少':''}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作前金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.old_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="操作后金额" >
							<template slot-scope='scope'>
								<div>{{scope.row.new_money | tofixed}}</div>
							</template>
						</el-table-column>
						<el-table-column label="原因"  show-overflow-tooltip>
							<template slot-scope='scope'>
								<div>{{scope.row.notes}}</div>
							</template>
						</el-table-column>
						<el-table-column prop="auditor" label="操作人" ></el-table-column>
						<el-table-column prop="add_time" label="操作时间" ></el-table-column>
					
					</el-table>
					<div class="flexX  mt20">
						<el-pagination :hide-on-single-page="true" @size-change="allReLoghandleSizeChange" @current-change="allReLoghandleCurrentChange"
						 :current-page="allReLogpage" :page-sizes="allReLogpageSizesArr" :page-size="allReLogpageSize" layout="total, sizes, prev, pager, next, jumper"
						 :total="allReLogtotal"></el-pagination>
					</div>
				</el-tab-pane>
				
			</el-tabs>
			
		</el-dialog>
	</div>
</template>

<script>
	import local from '@/util/local'
	import {
		isEmpty,
		isPositiveNumber,
		isMoney,
		isMobile,isNumber,
		isIncludeHanzi
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				tableData: [],
				loading: false,
				dialogloading: false,
				dialogVisible: false,
				dialogInnerloading: false,
				innerVisible: false,
				innerDialogTitle: '',
				height: 0,
				title: '详情',
				item: {},
				infoTotalData: {}, //搬砖工
				type: '',
				amount: '',
				activeName: 'info',
				cardListData: [], //银行卡列表
				diapageSizesArr: [20, 50, 100, 200],
				diapageSize: 20,
				diatotal: 0,
				diapage: 1,
				// 新增
				adddialogVisible: false,
				adddialogloading: false,
				labelPosition: 'right',
				account: '',
				name: '',
				password: '',
				passwordSure: '',
				fee: '',
				phone:'',
				skype:'',
				// feeAround:'3.0',
				//设置费率
				feepageSizesArr: [20, 50, 100, 200],
				feepageSize: 20,
				feetotal: 0,
				feepage: 1,
				feeData: [],
				//Log 日志
				logName:'recharge',
				logdialog:false,
				allLogdialog:false,
				logLoding:false,
				//充值|扣款  日志
				rechargeLog:[],
				reLogpageSizesArr:[20, 50, 100, 200],
				reLogpageSize:20,
				reLogtotal:0,
				reLogpage:1,
				//充值|扣款  总体日志
				allRechargeLog:[],
				allReLogpageSizesArr:[15, 50, 100, 200],
				allReLogpageSize:15,
				allReLogtotal:0,
				allReLogpage:1,
				//清空余额	日志		
				deductionLog:[],
				dedLogpageSizesArr:[20, 50, 100, 200],
				dedLogpageSize:20,
				dedLogtotal:0,
				dedLogpage:1,
				//费率日志
				feeLog:[],
				feeLogpageSizesArr:[20, 50, 100, 200],
				feeLogpageSize:20,
				feeLogtotal:0,
				feeLogpage:1,
				cardListdate:null,
				cardListdisLoading:false,
				carder_id: '',
				options: [],
				change_radio: '2',
				tx_amount: '',
				tx_note: '',
				logChangeType:'recharge',
				setAmount_note: '',
				date: null,
				disLoading: false,
				googleCode:'',
				//调押金
				advance:{
					amount:'',
					googleCode:'',
					setAmount_note:'',
				}
			}
		},
		created() {
			this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
			this.date = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
			this.treeList()
			this.carderAll();
			this.$nextTick(() => {

				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20
			})

		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getData(){
				// this.diapage = 1;
				this.carderAll()
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate, newDate]
				this.carderAll()
			},
			//导出Excel
			getExcel() {
				///manager/card/exportCardList
				console.log(this.$baseURL)
				
				let startdate = this.cardListdate ? this.cardListdate[0] : ''
				let enddate = this.cardListdate ? this.cardListdate[1] : ''
					
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				// }
				let href =
					`${this.$baseURL}manager/card/exportCardList?username=${username}&tid=${tid}&carder_id=${this.item.id}&startdate=${startdate}&enddate=${enddate}`
				window.location.href = href
			},
			//导出Excel
			getExcelCarder() {
				
				let startdate = this.date ? this.$util.timestampToTime(new Date(this.date[0]).getTime(), true) : ''
				let enddate = this.date ? this.$util.timestampToTime(new Date(this.date[1]).getTime(), true) : ''
				
				// let data = {
				let username = local.get('username');
				let tid = local.get('tid')
				// }
				let href =
					`${this.$baseURL}manager/carder/exportAll?username=${username}&tid=${tid}&start_time=${startdate}&end_time=${enddate}`
				// window.location.href = href
				window.open(href)
			},
			tableLogRowClassName({row, rowIndex}) {
				if (row.change_type === 'IN') {
					return 'Log-in-row';
				} else if (row.change_type === 'OUT') {
					return 'Log-out-row';
				}
				return '';
			},
			agentChang(value) {
				this.carder_id = value
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			cardListsetDate(day) {
				let data = this.cardListdate?this.cardListdate[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				this.cardListgetData()
				// console.log(this.date)
			},
			cardListgetData(){
				this.diapage = 1;
				this.cardList()
			},
			logListsetDate(day) {
				let data = this.cardListdate?this.cardListdate[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.cardListdate = [newDate, newDate]
				this.logListgetData()
				// console.log(this.date)
			},
			logListgetData(){
				this.diapage = 1;
				
				if(this.logChangeType == 'recharge'){
					this.allRechargeLogList()
				}else if(this.logChangeType == 'txmoney'){
					this.allTxmoneyLogList()
				}
			},
			carderAll() {
				// this.loading = true
				let data = {
					start_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), true) : '',
					end_time: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), true) : '',
				}
				this.$api.carderAll(data).then(res => {
					this.loading = false
					if (res.status === 1) {
						res.data.forEach(item=>{
							item.switchState = item.state == 0?true:false
							item.switchManual_state = item.manual_state == 0?false:true;
						})
						this.tableData = res.data
						// this.tableData.push(...res.data)
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(res => {
					this.loading = false
				})
			},
			//删除搬砖工
			deleteCarder(item){
				this.$confirm(`确认删除搬砖工${item.name}-${item.username}吗？`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					let data = {
						id:item.id
					}
					this.$api.carderDelete(data).then(res=>{
						this.loading = false
						if(res.status == 1){
							this.$message({
								type: 'success',
								message: `搬砖工删除成功`
							});
							this.carderAll()
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							});
						}
					}).catch(error=>{
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消删除搬砖工`
					});
				});
			},
			showInfo(item) {
				// this.carderInfo(item.id)
				this.item = item
				this.infoTotal()
				this.dialogVisible = true
			},
			close() {
				this.dialogVisible = false;
				this.item = {}
				this.cardListData = [] //银行卡列表
				this.activeName = 'info'
				this.diapage = 1
				this.cardListdate = [this.$util.timestampToTime(new Date().getTime(), true), this.$util.timestampToTime(new Date().getTime(),true)]
				this.advance={
					amount:'',
					googleCode:'',
					setAmount_note:'',
				};
				this.closeInner()
				
			},
			//获取详细信息
			carderInfo(carder_id) {
				// this.dialogloading = true;
				this.$api.carderInfo({
					carder_id: carder_id
				}).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						this.item = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			//查看代理
			queryAgent() {
				// this.dialogloading = true
				this.$api.showAgent({
					carder_id: this.item.id
				}).then(res => {
					this.dialogloading = false
					if (res.status === 1) {
						let agent = res.data.join(' -> ')
						this.$alert('上级代理为:' + agent, '上级代理', {
							confirmButtonText: '确定',
						});
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.dialogloading = false
				})

			},
			//停工  开工
			setState(item) {
				let newState = item.state == 0 ? 1 : 0
				let msg = newState == 0 ? '开工' : '停工'
				// if(newState == 0){
				//开工
				item. switchState = item.state == 0?true:false
// switchManual_state			
				this.$confirm(`${item.username}确认${msg}?`, `${msg}`, {
					confirmButtonText: `${msg}`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// if (newState == 0) {
					//开工
					let data = {
						carder_id: item.id,
						state: newState
					}
					this.dialogloading = true;
					this.$api.setState(data).then(res => {
						this.dialogloading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							// this.item.state = newState
							// this.infoTotal()
							this.carderAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${msg}`
					});
				});
			},
			//打开内层弹框
			openInnerDialog(type) {
				this.innerVisible = true
				this.type = type
				if (this.type === 'recharge') {
					this.innerDialogTitle = '充 值'
				} else if (this.type == 'deduction') {
					this.innerDialogTitle = '扣 款'
				} else if (this.type == 'limit') {
					this.innerDialogTitle = '限制充值'
				}else if (this.type == 'fee') {
					this.innerDialogTitle = '设置费率'
					this.carderFeeList()
				}else if (this.type == 'txmoney') {
					this.innerDialogTitle = '提现金额'
					// this.carderFeeList()
				}

			},
			//关闭内层弹框
			closeInner() {
				this.innerVisible = false;
				this.type = '';
				this.amount = '';
				this.tx_amount = '';
				this.tx_note = '';
				this.setAmount_note = '';
			},
			//设置费率
			suer() {
				// if (this.type == 'recharge' || this.type == 'deduction') {
				// 	//确认充值               
				// 	this.rechargeSuer();
				// } 
				// // else if (this.type == 'deduction') {
				// // 	//确认扣款
				// // 	this.deductionSuer();
				// // }
				//  else if (this.type == 'limit') {
				// 	//确认充值
				// 	this.limitRecharge();
				// } else if (this.type == 'fee') {
					let errorString = ''
					var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/
					
					for (let i = 0; i < this.feeData.length; i++) {
						if(this.feeData[i].fee === ''){
							errorString = this.feeData[i].name+'的手续费不能为空'
							break;
						}
						else if (!pattern.test(this.feeData[i].fee) && this.feeData[i].fee != 0 && this.feeData[i].fee != '0') {
							errorString = this.feeData[i].name+'的手续费只能是数字，最多两位小数'
							break;
						} else if (this.feeData[i].fee > this.feeData[i].max_fee) {
							errorString = this.feeData[i].name + '的手续费不能大于通道手续费!'
							break;
						}
					}
					if (errorString != '') {
						this.$message({
							type: 'error',
							message: errorString
						})
						return
					}
					let feeData = this.feeData.map(item => {
						return {
							"pay_type": item.pay_type,
							"fee": item.fee,
							// "fee_num":item.fee_num
						}
					})
					this.dialogInnerloading = true
					//设置手续费
					let data = {
						carder_id: this.item.id,
						data: JSON.stringify(feeData)
					}
					console.log('feedata', data)
					this.$api.setCarderFee(data).then(res => {
						this.dialogInnerloading = false
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: '手续费设置成功'
							})
							this.closeInner()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerloading = false
					})
				// } else if (this.type == 'txmoney') {
				// 	//确认扣款
				// 	this.txmoneySuer();
				// }
			},
			//提现金额账变
			txmoneySuer() {
				
				//扣款
				if (isEmpty(this.tx_amount, '请输入账变金额')) {
					return
				}
				if (this.change_radio != 1 && this.change_radio != 2) {
					this.$message({
						type: 'error',
						message: '请选择账变类型'
					})
					return
				}
				if (!isMoney(this.tx_amount, '充值金额最多输入两位小数')) {
					return
				}
				let data = {
					carder_id: this.item.id,
					amount: this.tx_amount,
					type: this.change_radio == 1?'IN':'OUT',
					note: this.tx_note,
				}
				
				this.dialogInnerloading = true;
				this.$api.setTxMoney(data).then(res => {
					this.dialogInnerloading = false;
					if (res.status === 1) {
						this.innerVisible = false;
						this.infoTotal()
						this.carderAll()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogInnerloading = false;
				})
			},
			//确认充值
			rechargeSuer() {
				// console.log()
				this.$confirm(`${this.item.username}确认调额?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//充值
					if (isEmpty(this.amount, '请输入金额')) {
						return
					}
					if (!isNumber(this.amount, '请输入正确的金额')) {
						return
					}
					if (isEmpty(this.googleCode,'请输谷歌验证码')) {
						return
					}
					if (isEmpty(this.setAmount_note,'请输入备注')) {
						return
					}
					let data = {
						carder_id: this.item.id,
						amount: this.amount,
						note: this.setAmount_note,
						googleCode:this.googleCode,
					}
					// if(Number(this.amount) >= 0 ){
					// 	data.type = 'IN'
					// }else{
					// 	data.type = 'OUT'
					// }
					this.dialogInnerloading = true;					
					this.$api.carderSetAmount(data).then(res => {
						this.dialogInnerloading = false;
						if (res.status === 1) {
							this.innerVisible = false;
							this.infoTotal()
							this.carderAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消调额`
					});
				});
			},
			//确认扣款
			deductionSuer() {
				this.$confirm(`${this.item.username}确认扣款?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//扣款
					if (isEmpty(this.amount, '请输入扣款金额')) {
						return
					}
					if (!isNumber(this.amount, '请输入正确的金额')) {
						return
					}
					if (isEmpty(this.setAmount_note, '请输入扣款备注')) {
						return
					}
					// if (!isMoney(this.amount, '充值金额最多输入两位小数')) {
					// 	return
					// }
					let data = {
						carder_id: this.item.id,
						amount: this.amount,
						type: 'OUT',
						note: this.setAmount_note,
					}
					this.dialogInnerloading = true;
					this.$api.carderSetAmount(data).then(res => {
						this.dialogInnerloading = false;
						if (res.status === 1) {
							this.innerVisible = false;
							// this.infoTotal()
							this.carderAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消扣款`
					});
				});
			},
			//重置密码
			repassword() {
				this.$confirm(`确认重置${this.item.username}的密码?`, '重置密码', {
					confirmButtonText: '重置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 重置密码
					this.dialogloading = true;
					let data = {
						carder_id: this.item.id
					}
					this.$api.setPassword(data).then(res => {
						this.dialogloading = false;
						if (res.status === 1) {
							this.$alert('新密码为:' + res.data, '重置密码成功', {
								confirmButtonText: '确定',
							});
							// this.$message({
							// 	type: 'success',
							// 	message: res.msg
							// })
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消重置密码`
					});
				});
			},
			//清空余额
			clearAmount() {
				this.$confirm(`确认清空${this.item.username}的余额吗?`, '清空余额', {
					confirmButtonText: '清 空',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 清空余额
					this.dialogloading = true;
					let data = {
						carder_id: this.item.id
					}
					this.$api.emptyMoney(data).then(res => {
						this.dialogloading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							// this.infoTotal()
							this.carderAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消清空余额`
					});
				});
			},
			//限制充值
			limitRecharge() {
				this.$confirm(`确认设置${this.item.username}限制充值?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//限制充值
					if (isEmpty(this.amount, '请输入限制充值金额')) {
						return
					}
					if (!isMoney(this.amount, '限制充值金额最多输入两位小数')) {
						return
					}
					let data = {
						carder_id: this.item.id,
						money: this.amount,
					}
					this.dialogInnerloading = true;
					this.$api.setAdvanceMoney(data).then(res => {
						this.dialogInnerloading = false;
						if (res.status === 1) {
							this.innerVisible = false;
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消设置限制充值`
					});
				});
			},
			
			//重置谷歌验证码
			resGoogleCode() {
				this.$confirm(`确认要重置${this.item.username}的谷歌验证码吗?`, '重置谷歌验证码', {
					confirmButtonText: '重 置',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// 限制充值
					this.dialogloading = true;
					let data = {
						carder_id: this.item.id
					}
					this.$api.setGoogleKey(data).then(res => {
						this.dialogloading = false;
						if (res.status === 1) {
							this.$message({
								type: 'success',
								message: res.msg
							})
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false;
					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消重置谷歌验证码`
					});
				});
			},
			
			diahandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.diapage = 1
				this.diapageSize = val
				this.cardList()
			},
			diahandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.diapage = val
				this.cardList()
			},
			getInfoData(val) {
				// this.setDate()
				if (val.name == 'info') {
					if (JSON.stringify(this.item) == '{}') {
						this.infoTotal()
					}
				}
				if (val.name == 'bankList') {
					if (this.cardListData.length == 0) {
						this.cardList()
					}
				}
				if(val.name == 'changeFee'){
					this.carderFeeList()
				}
			},
			infoTotal() {
				let data = {
					id: this.item.id,
					type: 1
				}
				// this.dialogloading = true
				this.$api.infoTotal(data).then(res => {
					this.dialogloading = false
					if (res.status == 1) {
						this.item = res.data
					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogloading = false
				})
			},
			cardList() {
				let data = {
					carder_id: this.item.id,
					size: this.diapageSize,
					page: this.diapage,
					startdate: this.cardListdate ? this.cardListdate[0] : '',
					enddate: this.cardListdate ? this.cardListdate[1] : '',
				}

				this.cardListdisLoading = true
				this.$api.cardList(data).then(res => {
					this.cardListdisLoading = false
					if (res.status == 1) {
						this.cardListData = res.data.data
						this.diatotal = res.data.total

					} else {
						this.$message({
							"type": "error",
							message: res.msg
						})
					}
				}).catch(error => {
					this.cardListdisLoading = false
				})
			},
			//新增按钮
			addAccount() {
				this.adddialogVisible = true
			},
			onSubmit() {
				if (isEmpty(this.account, '搬砖工账号不能为空')) {
					return
				}
				if (isIncludeHanzi(this.account, '搬砖工账号不能包含汉字')) {
					return
				}
				if (isEmpty(this.name, '搬砖工名称不能为空')) {
					return
				}
				if (isEmpty(this.password, '搬砖工密码不能为空')) {
					return
				}
				if (this.passwordSure != this.password) {
					this.$message({
						type: 'error',
						message: '两次输入密码不一致'
					})
					return
				}
				
				if (isEmpty(this.phone, '搬砖工手机号不能为空')) {
					return
				}
				if (!isMobile(this.phone, '输入的手机号不合法')) {
					return
				}
				// if (isEmpty(this.fee, '搬砖工手续费不能为空')) {
				// 	return
				// }
				// if (!isMoney(this.fee, '搬砖工手续费只能为数字，且最多输入两位小数')) {
				// 	return
				// }
				let data = {
					carder_username: this.account,
					name: this.name,
					password: this.$md5(this.password),
					fee: this.fee,
					telephone: this.phone,
					skype: this.skype,
					pid:0
				}
				this.adddialogloading = true;
				this.$api.carderAdd(data).then(res => {
					this.adddialogloading = false;
					if (res.status === 1) {
						this.adddialogVisible = false;
						this.carderAll()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.adddialogloading = false;
				})
			},
			addclose() {
				this.account = ''
				this.name = ''
				this.password = ''
				this.passwordSure = ''
				this.fee = ''
				this.phone=''
				this.skype=''
				this.adddialogVisible = false
			},
			//手续费列表
			carderFeeList() {
				let data = {
					carder_id: this.item.id,
					page: this.feepage,
					size: this.feepageSize
				}
				// this.dialogInnerloading = true
				this.$api.carderFeeList(data).then(res => {
					this.dialogInnerloading = false
					if (res.status === 1) {
						this.feeData = res.data.data
						this.feetotal = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.dialogInnerloading = false
				})
			},
			feehandleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.feepage = 1
				this.feepageSize = val
				this.carderFeeList()
			},
			feehandleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.feepage = val
				this.carderFeeList()
			},
			//日志
			showLog(item){
				this.logdialog = true
				this.item = item
				this.rechargeLogList();
			},
			logclose(){
				this.logdialog = false
				this.logName = 'recharge'
				this.rechargeLog = []
				this.deductionLog = []
				this.feeLog = []
				this.item = {}
			},
			// 总体日志
			showAllLog(){
				this.allLogdialog = true
				this.allRechargeLogList();
			},
			allLogclose(){
				this.allLogdialog = false
				this.logName = 'recharge'
				this.allRechargeLog = []
			},
			//总体 充值|扣款
			allRechargeLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					carder_id:this.carder_id,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
				}
				this.logLoding = true;
				this.$api.advanceMoneyList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.allRechargeLog = res.data.data;
						this.allReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			//总体 可提现金额列表
			allTxmoneyLogList(){
				let data = {
					page:this.allReLogpage,
					size:this.allReLogpageSize,
					carder_id:this.carder_id,
					start_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[0])), true) : '',
					end_time: this.cardListdate ? this.$util.timestampToTime(Date.parse(new Date(this.cardListdate[1])), true) : '',
				}
				this.logLoding = true;
				this.$api.txMoneyList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.allRechargeLog = res.data.data;
						this.allReLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			
			getlogData(val){
				if (val.name == 'recharge') {
					if(this.rechargeLog.length == 0){
						this.rechargeLogList();
					}
				}
				if(val.name == "deduction"){
					if(this.deductionLog.length == 0){
						this.deductionLogList();
					}
				}
				if(val.name == "fee"){
					if(this.feeLog.length == 0){
						this.feeLogList();
					}
				}
			},
			allGetlogData(val){
				this.carder_id = ''
				this.logChangeType = val.name
				this.allRechargeLog = []
				if (val.name == 'recharge') {
					if(this.allRechargeLog.length == 0){
						this.allRechargeLogList();
					}
				}
				if(val.name == 'txmoney') {
					if(this.allRechargeLog.length == 0){
						this.allTxmoneyLogList();
					}
				}
			},
			//充值|扣款
			rechargeLogList(){
				let data = {
					page:this.reLogpage,
					size:this.reLogpageSize,
					id:this.item.id,
					type:1,
				}
				this.logLoding = true;
				this.$api.logList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.rechargeLog = res.data.data;
						this.reLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			reLoghandleSizeChange(val){
				this.reLogpage = 1
				this.reLogpageSize = val
				this.rechargeLogList()
			},
			reLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.reLogpage = val
				this.rechargeLogList()
			},
			allReLoghandleSizeChange(val){
				this.allReLogpage = 1
				this.allReLogpageSize = val
				if(this.logChangeType == 'recharge'){
					this.allRechargeLogList()
				}else if(this.logChangeType == 'txmoney'){
					this.allTxmoneyLogList()
				}
			},
			allReLoghandleCurrentChange(val){
				// console.log(`当前页: ${val}`);
				this.allReLogpage = val
				if(this.logChangeType == 'recharge'){
					this.allRechargeLogList()
				}else if(this.logChangeType == 'txmoney'){
					this.allTxmoneyLogList()
				}
			},
			//清空余额
			deductionLogList(){
				let data = {
					page:this.dedLogpage,
					size:this.dedLogpageSize,
					id:this.item.id,
					type:2,
				}
				this.logLoding = true;
				this.$api.logList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.deductionLog = res.data.data;
						this.dedLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			dedLoghandleSizeChange(val){
				this.dedLogpage = 1
				this.dedLogpageSize = val
				this.deductionLogList()
			},
			dedLoghandleCurrentChange(val){
				console.log(`当前页: ${val}`);
				this.dedLogpage = val
				this.deductionLogList()
			},
			//手续费
			feeLogList(){
				let data = {
					page:this.feeLogpage,
					size:this.feeLogpageSize,
					id:this.item.id,
					type:3,
				}
				this.logLoding = true;
				this.$api.logList(data).then(res=>{
					this.logLoding = false;
					if(res.status == 1){
						this.feeLog = res.data.data;
						this.feeLogtotal = res.data.total
					}else{
						this.$message({type:"error",message:res.msg})
					}
				}).catch(error=>{
					this.logLoding = false;
				})
			},
			feeLoghandleSizeChange(val){
				this.feeLogpage = 1
				this.feeLogpageSize = val
				this.feeLogList()
			},
			feeLoghandleCurrentChange(val){
				console.log(`当前页: ${val}`);
				this.feeLogpage = val
				this.feeLogList()
			},
			//设置充值状态
			setPay_state(item) {
				let pay_state = item.pay_state == 1 ? 0 : 1
				this.$confirm(`确定改变充值状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					
					let data = {
						id: item.id,
						pay_state: pay_state
					}
					this.dialogloading = true;
					this.$api.carderEdit(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.carderAll()
							// this.infoTotal()
							// this.cardList()
							this.$message({
								type: "success",
								message: res.msg
							})
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变充值状态'
					});
				});
			},
			//设置代付状态
			setRepay_state(item) {
				let repay_state = item.repay_state == 1 ? 0 : 1
				this.$confirm(`确定改变充值状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id: item.id,
						repay_state: repay_state
					}
					this.dialogloading = true
					this.$api.carderEdit(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.carderAll()()
							this.$message({
								type: "success",
								message: res.msg
							})
							// if (repay_state == 1) {
							// 	item.repay_state = 1
							// }
							// if (repay_state == 0) {
							// 	item.repay_state = 0
							// }
							// console.log('pay_state',pay_state)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变充值状态'
					});
				});
			},
			setManual_state(item){
				let manual_state = item.manual_state == 1 ? 0 : 1
				item.switchManual_state = item.manual_state == 0?false:true
				this.$confirm(`确定改变手动充值&代付状态?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						carder_id: item.id,
						state: manual_state
					}
					this.dialogloading = true
					this.$api.setManualState(data).then(res => {
						this.dialogloading = false
						if (res.status == 1) {
							this.carderAll()
							this.$message({
								type: "success",
								message: res.msg
							})
							if (manual_state == 1) {
								thismanual_state = 1
							}
							if (manual_state == 0) {
								item.manual_state = 0
							}
							// console.log('pay_state',pay_state)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogloading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消改变手动充值&代付状态'
					});
				});
			},
			changeAdvanceMoney(item){
				this.$confirm(`${this.item.username}确认调押金?`, '提示', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					//充值
					if (isEmpty(this.advance.amount, '请输入金额')) {
						return
					}
					if (!isNumber(this.advance.amount, '请输入正确的金额')) {
						return
					}
					if (isEmpty(this.advance.googleCode,'请输谷歌验证码')) {
						return
					}
					if (isEmpty(this.advance.setAmount_note,'请输入充值备注')) {
						return
					}
					let data = {
						carder_id: this.item.id,
						amount: this.advance.amount,
						note: this.advance.setAmount_note,
						googleCode:this.advance.googleCode,
					}
					// if(Number(this.amount) >= 0 ){
					// 	data.type = 'IN'
					// }else{
					// 	data.type = 'OUT'
					// }
					this.dialogInnerloading = true;					
					this.$api.changeAdvanceMoney(data).then(res => {
						this.dialogInnerloading = false;
						if (res.status === 1) {
							this.innerVisible = false;
							this.infoTotal()
							this.carderAll()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.dialogInnerloading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消充值`
					});
				});
			}
			
		},
	}
</script>

<style lang="scss">
	@import '@/style/variables.scss';

	.el-table .Log-in-row{
		background-color: #5bc0de5c;
	}
	.el-table .Log-out-row{
		background-color: #d94f4f24;
	}

	.formBox {
		width: 80%;
		margin: 0 auto;

		.lable {
			display: inline-block;
			width: 80px;
			text-align: right;
		}

	}

	.feeAround {
		flex: 0 0 120px
	}
</style>
